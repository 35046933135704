import { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactTable } from "components/Table";
import { ModalChangeText, ModalTemplate } from "./modal";
import { countString, formatterCreateAt } from "components/Function";
import { createProductsByTemplate } from "actions/productActions";
import { CSVLink } from "react-csv";
import { getTikTokFirstSale } from "actions/spytoolActions";
import Loading from "components/Loading";
import Swal from "sweetalert2";

function Main({ createProductsByTemplate, user, countString, formatterCreateAt, getTikTokFirstSale }) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(100);
    const { t } = useTranslation();
    const [list, setList] = useState([]);
    const [products_selected, setProductsSelected] = useState([]);
    const [products_selected_export, setProductsSelectedExport] = useState([]);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const [searchText, setSearchText] = useState("");
    const btnRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    var { expired_at } = user.user;


    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    const onClickExport = () => {
        var products_selected_export = [];
        products_selected_export = list?.filter((v, i) => products_selected?.includes(v.id))?.map((v, i) => {
            return { id: v.id, title: v.title, images: v.images.join() }
        })
        setProductsSelectedExport(products_selected_export);
        setInitiateDownload(true);
    }

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "id",
            text: t("Product ID"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment>
                <div className="h3">

                    <Link target="_blank" to={"https://shop.tiktok.com/view/product/" + cell}>
                        {cell} - Sold Count: {row?.sold_count || 0}
                    </Link>
                </div>
                {row?.images.map((v, i) => {
                    return (
                        <img
                            className="al-product-img al-cursor-move"
                            style={{ objectFit: "cover", width: "400px" }}
                            src={v || require("components/assets/media/misc/image.png")}
                            alt=""
                            draggable="false"
                            key={i}
                        />
                    );
                })}
            </Fragment>,
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            classes: "al-cursor-pointer",
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                    <div>
                        <span className={cell?.length < 140 && cell?.length > 40 ? "text-success" : "text-danger"}>
                            Total Title: {cell?.length} <span className="text-info">{countString(cell)}</span>
                        </span>
                    </div>
                    {cell} <i className="fa fa-pencil"></i>
                </Fragment>
            ),
        },
        {
            dataField: "shop_nickname",
            text: t("Shop Name"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment>{row.shop_nickname} - {row.shop_id}</Fragment>,
        },
    ];


    const deleteProducts = () => {
        setList((prev) => [...prev?.filter((v) => !products_selected.includes(v.id))]);
    };

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row.id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row.id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = list.map((v, i) => v.id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    const groupButton = () => {
        return <div className="mb-5"></div>;
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(100);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const onClickFirstSale = async (data) => {
        setIsLoading(true)
        await getTikTokFirstSale().then((data) => {
            setList(data?.data?.data?.map((v, i) => {
                var name = v?.name?.replace(/&#39;/g, "");
                return { ...v, name };
            }) || [])
            setIsLoading(false)

            if (data.status === 200) {
                return Swal.fire(t("Success"), data.message, "success");
            }
            return Swal.fire(t("Error"), data.message, "error");
        })
            .catch((err) => {
                console.log(err)
            });
    };

    const handleChangeText = (data) => {
        setList((prev) => {
            prev = prev.map((item) => {
                if (!products_selected.includes(item.id)) return item;
                let title = item?.title;
                if (data?.textNew && data?.textOld) {
                    title = title?.replace(new RegExp(data?.textOld, "gi"), data?.textNew);
                }

                if (data?.textStart) title = `${data?.textStart} ${title}`;
                if (data?.textEnd) title = `${title} ${data?.textEnd}`;

                return { ...item, title };
            });
            return prev;
        });
    };

    const uploadProduct = (templateId, otherImage, numberImage) => {
        createProductsByTemplate({
            templateId,
            data: list.reduce((result, item) => {
                if (products_selected.includes(item.id)) {
                    const itemAdd = {
                        shop_id: item.shop_id,
                        product_id: item.product_id,
                        shop_name: item.shop_name,
                        title: item.title,
                        images: item.images,
                        note: item.note,
                        main_images: item.images.slice(0, numberImage)
                    };
                    if (otherImage?.length > 0) itemAdd.main_images = [...(itemAdd.main_images || []), ...otherImage];
                    result.push(itemAdd);
                }
                return result;
            }, []),
        });
    };

    const handleTableSaveCell = (oldValue, newValue, row, column) => {
        row[column.dataField] = newValue;
        setList((prev) => {
            const data = prev.map((product) => {
                if (product.id === row.id) {
                    return { ...product, [column.dataField]: newValue };
                }
                return { ...product };
            });
            return [...data];
        });
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: list.length || 10
        }
    ];

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="d-flex gap-4">
                {
                    expired_at - Date.now() >= 0 ?
                        <Fragment>
                            <button onClick={onClickFirstSale} className="btn btn-info btn-sm me-1"><i className="ki-outline ki-exit-right"></i>{t("Crawl First Sale")}</button>

                            <ModalChangeText onSubmit={handleChangeText} />
                            <ModalTemplate onSubmit={uploadProduct} />
                            <button onClick={onClickExport} className="btn btn-success btn-sm me-1" disabled={products_selected.length <= 0}><i className="ki-outline ki-exit-down"></i>{t("Export")}</button>
                            <CSVLink className="d-none" filename={"etsy_" + formatterCreateAt((new Date()).getTime(), "dmy") + ".csv"} data={products_selected_export}>
                                <span ref={btnRef} />
                            </CSVLink>
                        </Fragment>
                        : ""
                }
                <button onClick={deleteProducts} type="button" className="btn btn-danger btn-sm me-1" disabled={products_selected.length <= 0}>
                    {t("Delete Products")}
                </button>
            </div>
            <div className="card mb-5 mb-xl-10">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable
                            keyField="id"
                            columns={columns}
                            data={list}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={list.length}
                            alClassName="table-layout-inherit"
                            remote={{ search: false, pagination: false, sort: false }}
                            isSelectRow={true}
                            selectRow={selectRow}
                            isCellEdit={true}
                            handleBeforeSaveCell={handleTableSaveCell}
                            sizePerPageList={sizePerPageList}
                        />
                    </div>

                </div>
            </div>

        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = { createProductsByTemplate, countString, formatterCreateAt, getTikTokFirstSale };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
