import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";
import { getListPromotionsProductsTikTok, extendPromotion, removePromotion } from "actions/promotionActions";
import { getShops } from "actions/shopActions";
import { getStaffs } from "actions/teamActions";
import { formatterCreateAt, subStringText } from "components/Function";
import Loading from "components/Loading";
import { ReactTable } from "components/Table";
import { textFilter } from 'react-bootstrap-table2-filter';
import Select from 'react-select';
import Uppromotionlogs from "./Uppromotionlogs";
import store from "store";

var nameFilter_shops;
var nameFilter_teams;
var nameFilter_activity_type;

function Main(props) {
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(50);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [shops, setShops] = useState([]);
	const [filterShop, setFilterShop] = useState("");
	const [teams, setTeams] = useState([]);
	const [filterTeam, setFilterTeam] = useState("");
	const [activity_type, setActivityType] = useState("");
	const [filterActivityType, setFilterActivityType] = useState("");
	const [promotionproducttiktoks_selected, setPromotionProductTiktoksSelected] = useState([]);
    const [check_all_promotionproducttiktoks, setCheckAllPromotionProductTiktoks] = useState(false);
	const { t } = useTranslation();
    var { isLoading, listpromotionproducttiktoks, totalsize_listpromotionproducttiktoks } = props.promotion;
    var { list } = props.shop;
    var { list: listteams } = props.team;
    var { socket_id } = props.website;
    var { getListPromotionsProductsTikTok, extendPromotion, formatterCreateAt, getShops, removePromotion, subStringText, getStaffs } = props;
	const list_shop = list.map((v, i) => {
		return { "label": `${v.name} (${v.note} - ${v.code}) ${v.region}` , "value": v._id };
	});
	const list_team = listteams.map((v, i) => {
		return { "label": `${v.user.email}` , "value": v.user._id };
	});
    const list_activity_type = [
        // { "label": `FIXED_PRICE`, "value": `FIXED_PRICE` },
        { "label": `DIRECT_DISCOUNT`, "value": `DIRECT_DISCOUNT` },
        { "label": `FLASHSALE`, "value": `FLASHSALE` },
    ];

	useEffect(() => {
		getListPromotionsProductsTikTok(page, sizePerPage, searchText, filterShop, filterTeam, filterActivityType);
	}, [getListPromotionsProductsTikTok, page, sizePerPage, searchText, filterShop, filterTeam, filterActivityType]);

	useEffect(() => {
		setTotalSize(totalsize_listpromotionproducttiktoks);
	}, [totalsize_listpromotionproducttiktoks]);

	useEffect(() => {
		getShops({page: 1, sizeperpage: 999});
	}, [getShops]);

	useEffect(() => {
		getStaffs({page: 1, sizeperpage: 999});
	}, [getStaffs]);
	
	// const onClickExtendPromotion = (row = [], formatExtraData) => {
	// 	if (typeof formatExtraData !== "undefined") {
	// 		row = {
	// 			promotionproducttiktoks_selected: [row._id],
	// 			socket_id: formatExtraData.socket_id,
	// 			page: formatExtraData.page,
	// 			sizePerPage: formatExtraData.sizePerPage,
	// 			searchText: formatExtraData.searchText
	// 		}
	// 	}
	// 	extendPromotion(row);
	// };
	
	const onClickExtendPromotionSelect = () => {
		store.dispatch({
			type: 'UP_PROMOTION_LOGS',
			payload: { successfully: 0, failed: 0, logs_arr: [] }
		});
		store.dispatch({
			type: 'UP_PROMOTION_PERCENT',
			payload: { percent: 0 }
		});

		var row = {
			socket_id,
			page,
			sizePerPage,
			searchText,
			filterShop,
			filterTeam,
			filterActivityType,
			setCheckAllPromotionProductTiktoks
		};
		if (!check_all_promotionproducttiktoks) {
			row = {
				...row,
				promotionproducttiktoks_selected,
			}
		}
		extendPromotion(row);
	};
	
	const onClickRemovePromotionSelect = () => {
		var row = {
			page,
			sizePerPage,
			searchText,
			filterShop,
			filterTeam,
			filterActivityType,
			setCheckAllPromotionProductTiktoks
		};
		if (!check_all_promotionproducttiktoks) {
			row = {
				...row,
				promotionproducttiktoks_selected,
			}
		}
		removePromotion(row);
	};
	
	const onClickSelectAllProducts = async () => {
		setPromotionProductTiktoksSelected([]);
		setCheckAllPromotionProductTiktoks(!check_all_promotionproducttiktoks);
	};

	const onChangeShop = (e) => {
		var shops_tmp = e.map((v, i) => {
			return v.value
		});
        setShops(shops_tmp);
		nameFilter_shops(shops_tmp.join(","));
	}

	const onChangeTeam = (e) => {
		var teams_tmp = e.map((v, i) => {
			return v.value
		});
        setTeams(teams_tmp);
		nameFilter_teams(teams_tmp.join(","));
	}

	const onChangeActivityType = (e) => {
        setActivityType(e?.value);
		nameFilter_activity_type(e?.value);
	}

	const columns = [{
		dataField: '_id',
		text: "",
		sort: true,
		formatter: (cell, row, rowIndex) => rowIndex + 1
	}, {
		dataField: 'promotions',
		text: t("Promotion"),
		sort: true,
		formatter: (cell, row) => cell?.title
	}, {
		dataField: 'shops',
		text: t("Shop"),
		sort: true,
		formatter: (cell, row) => `${cell?.name} (${cell?.region})`,
	}, {
		dataField: 'shop_id',
		text: t("Shop ID"),
		sort: true,
		headerClasses: 'd-none',
		classes: 'd-none',
		formatter: (cell, row) => `${cell?.name} (${cell?.region})`,
		filter: textFilter({
			className: 'd-none',
			getFilter: (filter) => {
				nameFilter_shops = filter;
			}
		})
	}, {
		dataField: 'created_by',
		text: t("Created By"),
		sort: true,
		headerClasses: 'd-none',
		classes: 'd-none',
		formatter: (cell, row) => `${cell?.email}`,
		filter: textFilter({
			className: 'd-none',
			getFilter: (filter) => {
				nameFilter_teams = filter;
			}
		})
	}, {
		dataField: 'producttiktoks',
		text: t("Product"),
		sort: true,
		formatter: (cell, row) => (
			<Fragment>
				<div className="symbol symbol-50px me-2">
					<img src={cell?.main_images?.[0]||require("components/assets/media/misc/image.png")} alt="" />
				</div>
				<div className="d-inline-block">
					<span className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">{subStringText(cell?.title, 130)}</span>
				</div>
			</Fragment>
		)
	}, {
		dataField: 'activity_type',
		text: t("Activity Type"),
		sort: true,
		filter: textFilter({
			className: 'd-none',
			getFilter: (filter) => {
				nameFilter_activity_type = filter;
			}
		})
	}, {
		dataField: 'expired_at',
		text: t("Expired At"),
		sort: true,
        formatter: (cell, row) => (new Date()).getTime() <= cell ? formatterCreateAt(cell) : <span className="badge badge-light-danger">Expired</span>
	// }, {
	// 	dataField: '-',
	// 	text: t("Actions"),
	// 	formatExtraData: {page, sizePerPage, searchText, socket_id},
	// 	formatter: (cell, row, rowIndex, formatExtraData) =>
	// 		<Fragment>
	// 			<button onClick={() => { onClickExtendPromotion(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-success btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Extend")}>
	// 				<i className="ki-outline ki-arrows-loop fs-2"></i>
	// 			</button>
	// 		</Fragment>

	}];

	const groupButton = () => {
		return (
			<div className="mb-5">
				{/* <button onClick={() => { onClickExtendPromotionSelect() }} type="button" className="btn btn-primary btn-sm me-2">
					<i className="ki-outline ki-arrows-loop fs-2"></i> {t("Extend Promotion Selected")}
				</button> */}
				<button onClick={() => { onClickRemovePromotionSelect() }} type="button" className="btn btn-danger btn-sm me-2">
					<i className="ki-outline ki-trash fs-2"></i> {t("Remove Promotion Selected")}
				</button>
				<button onClick={() => { onClickSelectAllProducts() }} type="button" className={cx("btn btn-sm me-2", { "btn-secondary": !check_all_promotionproducttiktoks, "btn-success": check_all_promotionproducttiktoks })}>
					<i className={cx("ki-outline fs-2", { "ki-cross-square": !check_all_promotionproducttiktoks, "ki-check-square": check_all_promotionproducttiktoks })}></i> {t("Select all products")}
				</button>
				<Select
					isClearable
					isMulti
					name="shop"
					options={list_shop}
					className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
					classNamePrefix="select"
					value={list_shop.filter(({ value }) => shops.includes(value))}
					onChange={(e) => { onChangeShop(e) }}
					placeholder={t("Select a shop ... ")}
				/>
				<Select
					isClearable
					isMulti
					name="team"
					options={list_team}
					className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
					classNamePrefix="select"
					value={list_team.filter(({ value }) => teams.includes(value))}
					onChange={(e) => { onChangeTeam(e) }}
					placeholder={t("Select a member ... ")}
				/>
				<Select
					isClearable
					name="activity_type"
					options={list_activity_type}
					className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
					classNamePrefix="select"
					value={list_activity_type.filter(({ value }) => activity_type === value)}
					onChange={(e) => { onChangeActivityType(e) }}
					placeholder={t("Select a activity type ... ")}
				/>
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText, filters }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(50);
			setSearchText(searchText);
		} else if (type === "filter") {
			var filterVal = "";
			if (filters.shop_id) {
				filterVal = filters.shop_id.filterVal;
			}
			var filterVal2= "";
			if (filters.created_by) {
				filterVal2 = filters.created_by.filterVal;
			}
			var filterVal3= "";
			if (filters.activity_type) {
				filterVal3 = filters.activity_type.filterVal;
			}
			setPromotionProductTiktoksSelected([]);
			setFilterShop(filterVal);
			setFilterTeam(filterVal2);
			setFilterActivityType(filterVal3);
		} else {
			setPage(page||1);
			setSizePerPage(sizePerPage);
		}
	}

	const removeSelect = (arr, value) => {
		var i = 0;
		while (i < arr.length) {
			if (arr[i] === value) {
				arr.splice(i, 1);
			} else {
				++i;
			}
		}
		return arr;
	}

	const onSelect = (row, isSelect) => {
		var promotionproducttiktoks_selected_tmp = promotionproducttiktoks_selected;
		if (isSelect) {
			promotionproducttiktoks_selected_tmp.push(row._id);
		} else {
			promotionproducttiktoks_selected_tmp = removeSelect(promotionproducttiktoks_selected_tmp, row._id);
		}
		var new_arr = JSON.stringify(promotionproducttiktoks_selected_tmp);
		setPromotionProductTiktoksSelected(JSON.parse(new_arr));
	};

	const onSelectAll = (isSelect, row) => {
		var promotionproducttiktoks_selected_tmp = promotionproducttiktoks_selected;
		if (isSelect) {
			promotionproducttiktoks_selected_tmp = row.map((v, i) => v._id);
		} else {
			promotionproducttiktoks_selected_tmp = [];
		}
		setPromotionProductTiktoksSelected(promotionproducttiktoks_selected_tmp);
	};

	const selectRow = {
		mode: 'checkbox',
		clickToSelect: false,
		selected: promotionproducttiktoks_selected,
		onSelect: onSelect,
		onSelectAll: onSelectAll
	};

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: totalsize_listpromotionproducttiktoks||50
        }
    ];

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
			<div className="card mb-5 mb-xl-10">
				<div className="card-body p-9">
					<div className="al-variants-table">
						<ReactTable columns={columns} data={listpromotionproducttiktoks} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName={cx("table-layout-inherit", { "al-disabled": check_all_promotionproducttiktoks })} isSelectRow={true} selectRow={selectRow} sizePerPageList={sizePerPageList} />
					</div>
				</div>
			</div>
			<div className="col-md-12">
				<Uppromotionlogs />
			</div>
        </Fragment>
    );
}

Main.propTypes = {
    getListPromotionsProductsTikTok: PropTypes.func.isRequired,
    extendPromotion: PropTypes.func.isRequired,
    formatterCreateAt: PropTypes.func.isRequired,
    getShops: PropTypes.func.isRequired,
    removePromotion: PropTypes.func.isRequired,
    subStringText: PropTypes.func.isRequired,
    getStaffs: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
	promotion: state.promotion,
	shop: state.shop,
	team: state.team,
	website: state.website
});
const mapDispatchToProps = {
    getListPromotionsProductsTikTok,
    extendPromotion,
    formatterCreateAt,
    getShops,
    removePromotion,
    subStringText,
    getStaffs,
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);