import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cx from "classnames";
import Masstic from "./Masstic";
import SearchShop from "./SearchShop";
import SearchProduct from "./SearchProduct";
import GetShopProduct from "./GetShopProduct";
import GetProduct from "./GetProduct";
import VideoAd from "./VideoAd";

function Main(props) {
    const [tab, setTab] = useState("masstic");
    const { t } = useTranslation();

    const onClick = (data) => {
        setTab(data);
    }

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-0 pb-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "masstic" })} to="/admin/spy/tiktokv2" onClick={(e) => { onClick("masstic") }}>{t("First Sale")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "search_shop" })} to="/admin/spy/tiktokv2" onClick={(e) => { onClick("search_shop") }}>{t("Search Shop")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "search_product" })} to="/admin/spy/tiktokv2" onClick={(e) => { onClick("search_product") }}>{t("Search Product")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "get_shop_product" })} to="/admin/spy/tiktokv2" onClick={(e) => { onClick("get_shop_product") }}>{t("Find Product Shop")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "get_product" })} to="/admin/spy/tiktokv2" onClick={(e) => { onClick("get_product") }}>{t("Find Product Link")}</Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "video" })} to="/admin/spy/tiktokv2" onClick={(e) => { onClick("video") }}>{t("Video")}</Link>
                        </li>
                    </ul>
                </div>
            </div>
            {
                tab === "masstic" ?
                    <Masstic />
                    : tab === "search_shop" ?
                        <SearchShop />
                        : tab === "search_product" ?
                            <SearchProduct />
                            : tab === "get_shop_product" ?
                                <GetShopProduct />
                                : tab === "get_product" ?
                                    <></>// <GetProduct />
                                    : tab === "video" ?
                                        <VideoAd />
                                        : <></>
            }
        </Fragment>
    );
}

Main.propTypes = {
};
const mapStateToProps = state => ({
    user: state.user,
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);