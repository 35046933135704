import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";
import moment from "moment";
import Cookies from "universal-cookie";

const loadUser = () => (dispatch, getState) => {
    axios
        .get("/api/user", tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                dispatch({
                    type: "USER_LOADED",
                    payload: res.data.data,
                });
                const { user } = res?.data?.data;
                if (user) {
                    const cookies = new Cookies(null, { path: "/" });
                    if (
                        (moment().add(2, "days").valueOf() - (+user?.expired_at || 0) > 0 &&
                            !cookies.get("packageExpire")) ||
                        moment().valueOf() > (+user?.expired_at || 0)
                    ) {
                        Swal.fire(
                            t("Warning"),
                            "The package is about to expire, please renew the package",
                            "warning"
                        ).then(() => {
                            cookies.set("packageExpire", "true", { expires: moment().add(2, "hours").toDate() });
                        });
                    }
                }
            } else {
                if (res.data.status !== 401) {
                    dispatch({
                        type: "AUTH_ERROR",
                    });
                }
                if (res.data.status === 401) {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            }
        })
        .catch((err) => {
            dispatch({
                type: "AUTH_ERROR",
            });
        });
};

const loadUserTransition =
    ({ page = 1, sizeperpage = 10, search = "" } = {}) =>
    (dispatch, getState) => {
        let param_search = "";
        if (search !== "") {
            param_search = `&search=${search}`;
        }
        return axios
            .get(`/api/user/transition?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState))
            .then((res) => {
                if (res.data.status === 200) {
                    return res.data.data;
                }
            })
            .catch((err) => {
                return null;
            });
    };

const rollbackTransition = (transitionId) => (dispatch, getState) => {
    return axios
        .post(`/api/user/transition/rollback`, { transitionId }, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                Swal.fire(t("Success"), t(res.data.message), "success");
                return true;
            }

            Swal.fire(t("Error"), t(res.data.message), "error");
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("Something error"), "error");
        });
};

const loginUser = (email, password) => (dispatch, getState) => {
    dispatch({ type: "LOADING_USER", payload: true });
    if (email.trim() === "" || password.trim() === "") {
        dispatch({ type: "LOADING_USER", payload: false });
        Swal.fire(t("Error"), t("Please enter your full account name and password!"), "error");
        return false;
    }
    var body = {
        email,
        password,
    };
    axios
        .post("/api/user/login", body, tokenConfig(getState))
        .then((res) => {
            dispatch({ type: "LOADING_USER", payload: false });
            if (res.data.status === 200) {
                dispatch({
                    type: "LOGIN_SUCCESS",
                    payload: res.data.data,
                });
                dispatch(loadUser());
            } else {
                dispatch({
                    type: "LOGIN_FAIL",
                });
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            dispatch({
                type: "LOGIN_FAIL",
            });
            Swal.fire(t("Error"), t("Wrong account name or password!"), "error");
        });
};

const registerUser = (email, password, repeatpassword, name) => (dispatch, getState) => {
    var body = {
        email,
        password,
        repeatpassword,
        name,
    };
    dispatch({ type: "LOADING_USER", payload: true });
    axios
        .post("/api/user/register", body, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                dispatch({ type: "LOADING_USER", payload: false });
                dispatch({
                    type: "REGISTER_SUCCESS",
                    payload: res.data.data,
                });
                dispatch(loadUser());
            } else {
                dispatch({
                    type: "REGISTER_FAIL",
                });
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            dispatch({
                type: "REGISTER_FAIL",
            });
            Swal.fire(t("Error"), t("Account registration failed!"), "error");
        });
};

const logoutUser = () => (dispatch, getState) => {
    dispatch({
        type: "LOGOUT_SUCCESS",
    });
};

const getListUsers =
    (page = 0, sizeperpage = 0, search = "") =>
    (dispatch, getState) => {
        dispatch({ type: "LOADING_USER", payload: true });
        var param_search = "";
        if (search !== "") {
            param_search = `&search=${search}`;
        }
        axios
            .get(`/api/user/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState))
            .then((res) => {
                dispatch({ type: "LOADING_USER", payload: false });
                if (res.data.status === 200) {
                    dispatch({
                        type: "ADMIN_LIST_USER",
                        payload: res.data.data,
                    });
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                }
            })
            .catch((err) => {
                dispatch({ type: "LOADING_USER", payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const saveAddUser = (data, closemodal) => (dispatch, getState) => {
    var body = {
        email: data.email,
        password: data.password,
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add user?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_USER", payload: true });
            axios
                .post("/api/user/add", body, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_USER", payload: false });
                    if (res.data.status === 200) {
                        closemodal();
                        dispatch(getListUsers(1, 10));
                        Swal.fire(t("Success"), t(res.data.message), "success");
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_USER", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        }
    });
};

const saveEditUser = (data, closemodal) => (dispatch, getState) => {
    var body = {
        _id: data._id,
        block: data.block,
        limit_shop: data.limit_shop,
        limit_staff: data.limit_staff,
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to edit user?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_USER", payload: true });
            axios
                .post("/api/user/edit", body, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_USER", payload: false });
                    if (res.data.status === 200) {
                        closemodal();
                        dispatch(getListUsers(data.page, data.sizePerPage, data.searchText));
                        Swal.fire(t("Success"), t(res.data.message), "success");
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_USER", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        }
    });
};

const deleteUser = (data) => (dispatch, getState) => {
    var body = {
        _id: data._id,
    };
    Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete user?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_USER", payload: true });
            axios
                .post("/api/user/delete", body, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_USER", payload: false });
                    if (res.data.status === 200) {
                        dispatch(getListUsers(data.page, data.sizePerPage, data.searchText));
                        Swal.fire(t("Success"), t(res.data.message), "success");
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_USER", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        }
    });
};

const editUser = (formData) => (dispatch, getState) => {
    axios
        .put("/api/user", formData, tokenConfig(getState))
        .then((res) => {
            dispatch({ type: "LOADING_USER", payload: false });
            if (res.data.status === 200) {
                dispatch(loadUser());
                Swal.fire(t("Success"), t(res.data.message), "success");
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            dispatch({ type: "LOADING_USER", payload: false });
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const updateBalanceUser = (formData) => (dispatch, getState) => {
    dispatch({ type: "LOADING_USER", payload: true });
    return axios
        .post("/api/user/update-balance", formData, tokenConfig(getState))
        .then((res) => {
            dispatch({ type: "LOADING_USER", payload: false });
            if (res.data.status === 200) {
                Swal.fire(t("Success"), t(res.data.message), "success");
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            dispatch({ type: "LOADING_USER", payload: false });
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const forgotPassword = (email) => (dispatch, getState) => {
    var body = {
        email,
    };
    dispatch({ type: "LOADING_USER", payload: true });
    axios
        .post("/api/user/forgot-password", body, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                dispatch({ type: "LOADING_USER", payload: false });
                Swal.fire(t("Success"), t(res.data.message), "success");
            } else {
                dispatch({
                    type: "REGISTER_FAIL",
                });
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            dispatch({
                type: "REGISTER_FAIL",
            });
            Swal.fire("Error", t("Email sent failed!"), "error");
        });
};
const resetPassword = (token, new_password, confirm_new_password) => (dispatch, getState) => {
    var body = {
        token,
        new_password,
        confirm_new_password,
    };
    dispatch({ type: "LOADING_USER", payload: true });
    axios
        .post("/api/user/reset-password", body, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                dispatch({ type: "LOADING_USER", payload: false });
                // Swal.fire("Success", res.data.message, "success");
                window.location.href = "/admin?success=" + res.data.message;
            } else {
                dispatch({
                    type: "REGISTER_FAIL",
                });
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            dispatch({
                type: "REGISTER_FAIL",
            });
            Swal.fire("Error", t("Reset password failed!"), "error");
        });
};

const saveTelegramToken = (formData) => (dispatch, getState) => {
    axios
        .put("/api/user", formData, tokenConfig(getState))
        .then((res) => {
            dispatch({ type: "LOADING_USER", payload: false });
            if (res.data.status === 200) {
                dispatch(loadUser());
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            dispatch({ type: "LOADING_USER", payload: false });
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const addUserShop = (formData) => (dispatch, getState) => {
    axios
        .post("/api/user/inc-shop", formData, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                Swal.fire(t("Success"), t(res.data.message), "success");
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

export {
    loadUser,
    loginUser,
    registerUser,
    logoutUser,
    getListUsers,
    saveAddUser,
    saveEditUser,
    deleteUser,
    editUser,
    forgotPassword,
    resetPassword,
    saveTelegramToken,
    updateBalanceUser,
    loadUserTransition,
    rollbackTransition,
    addUserShop,
};
