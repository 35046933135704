import { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";
import { getShops } from "actions/shopActions";
import { formatterCreateAt, FormatterStatus } from "components/Function";
import { ReactTable } from "components/Table";
import { deactivatePromotion } from "actions/promotionActions";
import { getStaffs } from "actions/teamActions";
import SelectBox from "components/elements/selectBox";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

function Listshops(props) {
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(50);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [toggleCard, setToggleCard] = useState(false);
	const [staffs, setStaffs] = useState([]);
	const [filter, setFilter] = useState({});

	const { t } = useTranslation();
	var { list, totalsize } = props.shop;
	var { getShops, shops_selected, setShopsSelected, deactivatePromotion, getStaffs, FormatterStatus, formatterCreateAt } = props;

	useEffect(() => {
		getStaffs({ sizeperpage: 100 }).then((data) => {
			setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
		});
	}, [getStaffs]);

	useEffect(() => {
		getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, pathname: "promotions" });
	}, [getShops, page, sizePerPage, searchText, filter]);

	useEffect(() => {
		setTotalSize(totalsize);
	}, [totalsize]);

	// const onClickDeactivateAllPromotion = (type) => {
	// 	deactivateAllPromotion({ filterShop: shops_selected.join(), type });
	// };

	const onClickDeactivatePromotion = (type) => {
		deactivatePromotion({ filterShop: shops_selected.join(), type });
	};

	const columns = [{
		dataField: '_id',
		text: "",
		sort: true,
		formatter: (cell, row, rowIndex) => rowIndex + 1
		// }, {
		// 	dataField: 'apiShopId',
		// 	text: t("Shop ID"),
		// 	sort: true,
	}, {
		dataField: 'name',
		text: t("Shop Name"),
		sort: true,
	}, {
		dataField: 'region',
		text: t("Region"),
		sort: true,
	},
	{
		dataField: "sync_product_status",
		text: t("Status Sync"),
		sort: true,
		formatter: (cell, row) => {
			let type = "success";
			if (cell?.status === "Running") type = "warning";
			if (cell?.status === "Error") type = "Error";
			return (
				<>
					<FormatterStatus type={type} status={cell?.status || "End"} />
					<p className="mt-2">Last Sync: {cell?.last_sync && cell?.last_sync > 0 ? formatterCreateAt(cell?.last_sync) : "Since 1905"}</p>
				</>
			);
		},
	},
	{
		dataField: 'orders_count',
		text: t("Total Order"),
		sort: true,
	}, {
		dataField: 'products_count',
		text: t("Total Product"),
		sort: true,
	}, {
		dataField: 'promotion_product_tiktok_count',
		text: t("Progress Promotion"),
		sort: true,
		formatter: (cell, row) => {
			var promotion_product_tiktok_count = row?.promotion_product_tiktok_count?.find((v, i) => v?._id?.activity_type === "FLASHSALE") || {count: 0};
			var percent = promotion_product_tiktok_count?.count / row.products_count * 100 < 100 ? ( promotion_product_tiktok_count?.count / row.products_count * 100).toFixed(2) : 100;
			var status = percent <= 20 ? "error" : percent <= 80 ? "active" : percent < 100 ? "default" : "success";
			return <Progress
				className="pe-10 ps-10 pt-5"
				status={status}
				percent={percent}
				theme={{
					error: {
						symbol: percent + '%',
						trailColor: 'pink',
						color: 'red'
					},
					default: {
						symbol: percent + '%',
						trailColor: 'lightblue',
						color: 'blue'
					},
					active: {
						symbol: percent + '%',
						trailColor: 'yellow',
						color: 'orange'
					},
					success: {
						symbol: percent + '%',
						trailColor: 'lime',
						color: 'green'
					}
				}}
			/>
		}
	}, {
		dataField: 'discount_product_tiktok_count',
		text: t("Progress Discount"),
		sort: true,
		formatter: (cell, row) => {
			var discount_product_tiktok_count = row?.promotion_product_tiktok_count?.find((v, i) => v?._id?.activity_type === "DIRECT_DISCOUNT") || {count: 0};
			var percent = discount_product_tiktok_count?.count / row.products_count * 100 < 100 ? ( discount_product_tiktok_count?.count / row.products_count * 100).toFixed(2) : 100;
			var status = percent <= 20 ? "error" : percent <= 80 ? "active" : percent < 100 ? "default" : "success";
			return <Progress
				className="pe-10 ps-10 pt-5"
				status={status}
				percent={percent}
				theme={{
					error: {
						symbol: percent + '%',
						trailColor: 'pink',
						color: 'red'
					},
					default: {
						symbol: percent + '%',
						trailColor: 'lightblue',
						color: 'blue'
					},
					active: {
						symbol: percent + '%',
						trailColor: 'yellow',
						color: 'orange'
					},
					success: {
						symbol: percent + '%',
						trailColor: 'lime',
						color: 'green'
					}
				}}
			/>
		}
	}, {
		dataField: 'note',
		text: t("Note"),
		sort: true,
	}, {
		dataField: "member_note",
		text: t("Member Note"),
		sort: true,
	}];

	const groupButton = () => {
		return (
			<div className="mb-5 d-flex gap-2 flex-wrap">
				{/* <button onClick={() => { onClickDeactivateAllPromotion("all") }} type="button" className="btn btn-danger btn-sm me-2">
					<i className="ki-outline ki-cross-circle fs-2"></i> {t("Deactivate All Promotion")}
				</button>
				<button onClick={() => { onClickDeactivateAllPromotion("limit") }} type="button" className="btn btn-warning btn-sm me-2">
					<i className="ki-outline ki-cross-circle fs-2"></i> {t("Deactivate Promotion Non Product")}
				</button> */}
				<button onClick={() => { onClickDeactivatePromotion("FLASHSALE") }} type="button" className="btn btn-danger btn-sm me-2">
					<i className="ki-outline ki-cross-circle fs-2"></i> {t("Clear All Flash Deal")}
				</button>
				<button onClick={() => { onClickDeactivatePromotion("DIRECT_DISCOUNT") }} type="button" className="btn btn-dark btn-sm me-2">
					<i className="ki-outline ki-cross-circle fs-2"></i> {t("Clear All Discount")}
				</button>
				<SelectBox
					options={staffs}
					name="filter_staff"
					value={filter.staff || ""}
					onChange={(data) =>
						setFilter((prev) => ({
							...prev,
							staff: data.value || "",
						}))
					}
				/>
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(50);
			setSearchText(searchText);
		} else {
			setPage(page || 1);
			setSizePerPage(sizePerPage);
		}
	}

	const removeSelect = (arr, value) => {
		var i = 0;
		while (i < arr.length) {
			if (arr[i] === value) {
				arr.splice(i, 1);
			} else {
				++i;
			}
		}
		return arr;
	}

	const onSelect = (row, isSelect) => {
		var shops_selected_tmp = shops_selected;
		if (isSelect) {
			shops_selected_tmp.push(row._id);
		} else {
			shops_selected_tmp = removeSelect(shops_selected_tmp, row._id);
		}
		var new_arr = JSON.stringify(shops_selected_tmp);
		setShopsSelected(JSON.parse(new_arr));
	};

	const onSelectAll = (isSelect, row) => {
		var shops_selected_tmp = shops_selected;
		if (isSelect) {
			shops_selected_tmp = row.map((v, i) => v._id);
		} else {
			shops_selected_tmp = [];
		}
		setShopsSelected(shops_selected_tmp);
	};

	const selectRow = {
		mode: 'checkbox',
		clickToSelect: false,
		selected: shops_selected,
		onSelect: onSelect,
		onSelectAll: onSelectAll
	};

	const sizePerPageList = [
		{
			text: '10', value: 10
		},
		{
			text: '50', value: 50
		},
		{
			text: '100', value: 100
		},
		{
			text: '150', value: 150
		},
		{
			text: '200', value: 200
		},
		{
			text: 'All', value: totalsize || 50
		}
	];

	return (
		<Fragment>
			<div className="card mb-5 mb-xl-10">
				<div className={cx("card-header collapsible cursor-pointer rotate", { active: !toggleCard, collapsed: toggleCard })} data-bs-toggle="collapse" data-bs-target="#kt_docs_card_collapsible" onClick={() => setToggleCard(!toggleCard)}>
					<h3 className="card-title">{t("List Shops")}</h3>
					<div className="card-toolbar rotate-180">
						<i className="ki-duotone ki-down fs-1"></i>
					</div>
				</div>
				<div id="kt_docs_card_collapsible" className={cx("collapse", { show: !toggleCard })}>
					<div className="card-body p-9">
						<div className="al-variants-table">
							<ReactTable columns={columns} data={list} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" isSelectRow={true} selectRow={selectRow} sizePerPageList={sizePerPageList} />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

Listshops.propTypes = {
	formatterCreateAt: PropTypes.func.isRequired,
	getShops: PropTypes.func.isRequired,
	getStaffs: PropTypes.func.isRequired,
	deactivatePromotion: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
	shop: state.shop
});
const mapDispatchToProps = {
	formatterCreateAt,
	getShops,
	getStaffs,
	FormatterStatus,
	deactivatePromotion,
}

export default connect(mapStateToProps, mapDispatchToProps)(Listshops);