import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTable } from "components/Table";
import { createProductsByTemplate } from "actions/productActions";
import { searchTikTok } from "actions/spytoolActions";
import Loading from "components/Loading";
import Swal from "sweetalert2";
import { formatterCreateAt, countString } from "components/Function";

function Main({ user, countString, formatterCreateAt, searchTikTok }) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(100);
    const { t } = useTranslation();
    const [list, setList] = useState([]);
    const [products_selected, setProductsSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchShopName, setSearchShopName] = useState("");
    var { expired_at } = user.user;

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "id",
            text: t("Shop ID"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment>
                <img
                    className="al-product-img al-cursor-move ms-5"
                    style={{ objectFit: "cover", width: "100px" }}
                    src={("https://d3uucz7wx6jq40.cloudfront.net/tiktok.seller/" + cell + "/logo.png") || require("components/assets/media/misc/image.png")}
                    alt=""
                />
            </Fragment>,
        },
        {
            dataField: "name",
            text: t("Name"),
            sort: true,            
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment>
                <div className="d-flex flex-column">
                    <div className="h4">
                        Name: {cell}
                    </div>
                    <div className="h5">
                        ID: {row?.id}
                    </div>
                    <div>
                        <i>Seller Type: <b>{row?.seller_type}</b></i>
                    </div>
                </div>
            </Fragment>,
        },
        {
            dataField: "revenue",
            text: t("Revenue"),
            sort: true,
            editable: false,
        },
        {
            dataField: "sale",
            text: t("Sales"),
            sort: true,
            editable: false,
        },
    ];


    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row.id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row.id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = list.map((v, i) => v.id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    const groupButton = () => {
        return <div className="mb-5"></div>;
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(100);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const onClickSearch = async (data) => {
        setIsLoading(true)
        await searchTikTok({ shop: searchShopName })
            .then((data) => {
                setList(data)
                setIsLoading(false)
                if (data.length > 0) {
                    return Swal.fire(t("Success"), data.length + " Shops", "success");
                }
                return Swal.fire(t("Error"), "Failed", "error");
            })
            .catch((err) => {
                return Swal.fire(t("Error"), err, "error");
            });
    };

    const handleTableSaveCell = (oldValue, newValue, row, column) => {
        row[column.dataField] = newValue;
        setList((prev) => {
            const data = prev.map((product) => {
                if (product.id === row.id) {
                    return { ...product, [column.dataField]: newValue };
                }
                return { ...product };
            });
            return [...data];
        });
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: list.length || 10
        }
    ];

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="d-flex gap-4">
                {
                    expired_at - Date.now() >= 0 ?
                        <Fragment>
                            <div className="d-flex">
                                <div className="form-group">
                                    <input type="text" value={searchShopName} name="searchShopName" className="form-control" placeholder="COTIK..." onChange={(e) => {
                                        setSearchShopName(e.target.value);
                                    }} />
                                </div>
                                <button onClick={onClickSearch} className="btn btn-info btn-sm me-1"><i className="ki-outline ki-filter-search"></i>{t("Search Shop")}</button>
                            </div>
                            <span>From: {(new Date((Date.now() - 2592000000)).toISOString().split("T")[0])}</span>
                            <span>To: {(new Date((Date.now() - 172800000)).toISOString().split("T")[0])}</span>
                        </Fragment>
                        : ""
                }
            </div>
            <div className="card mb-5 mb-xl-10">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable
                            keyField="id"
                            columns={columns}
                            data={list}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={list.length}
                            alClassName="table-layout-inherit"
                            remote={{ search: false, pagination: false, sort: false }}
                            isSelectRow={true}
                            selectRow={selectRow}
                            isCellEdit={true}
                            handleBeforeSaveCell={handleTableSaveCell}
                            sizePerPageList={sizePerPageList}
                        />
                    </div>

                </div>
            </div>

        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = { createProductsByTemplate, countString, formatterCreateAt, searchTikTok };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
