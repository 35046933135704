import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getProductSchedule =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_PRODUCT_SCHEDULE", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/product-schedule?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PRODUCT_SCHEDULE", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_PRODUCT_SCHEDULE",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PRODUCT_SCHEDULE", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const addProductSchedule =
    (data) =>
        async (dispatch, getState) => {
            if (data?._id !== "") {
                await axios
                    .post("/api/product-schedule/add", data, tokenConfig(getState))
                    .then((res) => {
                        if (res.data.status === 200) {
                            Swal.fire("Success", res.data.message, "success");
                        } else {
                            Swal.fire("Error", res.data.message, "error");
                        }
                    })
                    .catch((err) => {
                        Swal.fire("Error", "An error occurred!", "error");
                    });
            } else {
                Swal.fire("Error", "Not found data in system!", "error");
            }

        };

const deleteProductSchedule = (id) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then(async (result) => {
        if (result.value) {
            if (Array.isArray(id)) {
                return Promise.all(
                    id?.map((i) => {
                        return new Promise(async (resolve2, reject2) => {
                            axios
                                .delete(`/api/product-schedule/${i}`, tokenConfig(getState))
                                .then((res) => {
                                    if (res.data.status === 200) {
                                        resolve2(res.data.message)

                                    } else {
                                        resolve2(res.data.message)
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                    resolve2(err)
                                });
                        });
                    })).then(async (a) => {
                        Swal.fire(t("Success"), t("Delete Success"), "success");
                        return true;
                    })
            } else {
                return axios
                    .delete(`/api/product-schedule/${id}`, tokenConfig(getState))
                    .then((res) => {
                        if (res.data.status === 200) {
                            Swal.fire(t("Success"), t(res.data.message), "success");
                        } else {
                            Swal.fire(t("Error"), t(res.data.message), "error");
                        }
                        return true;
                    })
                    .catch((err) => {
                        console.log(err);
                        Swal.fire(t("Error"), t("An error occurred!"), "error");
                        return false;
                    });
            }
        }
    });
};

const productScheduleMulti = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to schedule product?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then(async (result) => {
        if (result.value) {
            var shop = data.shop;
            var upload_at = data.upload_at;
            var timeSleep = parseInt(data?.timeSleep || 0) * 1000;
            var products = data.products;
            var totalSuccess = 0;
            var totalFailed = 0;
            await Promise.all(
                products?.map((product, i) => {
                    return new Promise(async (resolve2, reject2) => {
                        var title = product?.title;
                        var _id = product?._id?.toString();
                        var main_images = product?.main_images?.[0] || "";
                        if (main_images !== "") {
                            await axios
                                .post("/api/product-schedule/add", { title, _id, main_images, shop, upload_at, timeSleep: timeSleep * (i + 1) }, tokenConfig(getState))
                                .then((res) => {
                                    if (res.data.status === 200) {
                                        totalSuccess++
                                    } else {
                                        totalFailed++;
                                    }
                                })
                                .catch((err) => {
                                    totalFailed++;
                                });
                        }
                        resolve2("")
                    });
                })).then(async (a) => {
                    Swal.fire(t("Success"), `Schedule Success ${totalSuccess}/Failed ${totalFailed} products`, "success");
                    return true;
                })
        }
    });
};

const productScheduleMulti2 = (data) => (dispatch, getState) => {
    console.log(data)
    return axios
        .post("/api/product-schedule/add", data, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => {
            Swal.fire(t("Error"), err, "error");
        });
};

export {
    getProductSchedule,
    addProductSchedule,
    deleteProductSchedule,
    productScheduleMulti,
    productScheduleMulti2,
};
