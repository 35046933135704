import axios from "axios";
import { tokenConfig } from "./websiteActions";
import Swal from "sweetalert2";
import { sleep } from "components/Function";
import { t } from "i18next";

const getSuggests =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_TRENDING", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/spytool/suggests?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_TRENDING", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_TRENDING",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_TRENDING", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getProductEsty = (listId, link) => (dispatch, getState) => {
    return axios
        .post(`/api/spytool/get-esty`, { listId, link }, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const getProductShopEsty = (link) => (dispatch, getState) => {
    return axios
        .post(`/api/spytool/get-shop-esty`, { link }, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const uploadTemplateByLink = (data) => async (dispatch, getState) => {
    await axios
        .post("/api/product-template/upload-by-link", { data }, tokenConfig(getState))
        .then((res) => res.data.data)
        .catch((err) => {
            return;
        });
};

const getProductTikTokv1 = (type, link, shop, proxy) => (dispatch, getState) => {
    return axios
        .get(`/api/spytool/tiktokv1?type=${type}&link=${link}&shop=${shop}&proxy=${proxy}`, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const getTikTokFirstSale = () => (dispatch, getState) => {
    return axios
        .get(`/api/spytool/first-sale`, tokenConfig(getState))
        .then((res) => res.data)
        .catch((err) => err);
};

const getAmazonProducts =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_AMAZON_PRODUCT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/spytool/amazon-product?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_AMAZON_PRODUCT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_AMAZON_PRODUCT",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_AMAZON_PRODUCT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getTikTokTopSales =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_TIKTOK_TOP_SALES", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/spytool/tiktok-top-sales?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_TIKTOK_TOP_SALES", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_TIKTOK_TOP_SALES",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_TIKTOK_TOP_SALES", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getTikTokBreakoutProduct =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_TIKTOK_BREAKOUT_PRODUCT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/spytool/tiktok-breakout-products?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_TIKTOK_BREAKOUT_PRODUCT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_TIKTOK_BREAKOUT_PRODUCT",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_TIKTOK_BREAKOUT_PRODUCT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getTikTokNewProduct =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_TIKTOK_NEW_PRODUCT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/spytool/tiktok-new-products?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_TIKTOK_NEW_PRODUCT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_TIKTOK_NEW_PRODUCT",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_TIKTOK_NEW_PRODUCT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getTikTokHotProduct =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_TIKTOK_HOT_PRODUCT", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/spytool/tiktok-hot-products?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_TIKTOK_HOT_PRODUCT", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_TIKTOK_HOT_PRODUCT",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_TIKTOK_HOT_PRODUCT", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const searchTikTok = (data) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        if (data?.shop && data?.shop !== "") {
            let dataShop = [];
            await searchShop({ shop: data?.shop, page: 1, dataShop, getState }).then(async (a) => resolve(a))
        } else if (data?.product && data?.product !== "") {
            let dataProduct = [];
            await searchProduct({ product: data?.product, page: 1, dataProduct, getState }).then(async (a) => resolve(a))
        } else if (data?.id && data?.id !== "") {
            let dataProduct = [];
            await searchShopProduct({ id: data?.id, page: 1, dataProduct, getState }).then(async (a) => resolve(a))
        } else if (data?.type && data?.type === "product_trend") {
            let dataProduct = [];
            await searchProduct({ product: "", page: 1, dataProduct, getState }).then(async (a) => resolve(a))
        } else if (data?.keyword && data?.keyword === "all") {
            let dataProduct = [];
            await searchVideo({ keyword: "", page: 1, dataProduct, getState }).then(async (a) => resolve(a))
        } else if (data?.keyword && data?.keyword !== "all") {
            let dataProduct = [];
            await searchVideo({ keyword: data?.keyword, page: 1, dataProduct, getState }).then(async (a) => resolve(a))
        } else {
            resolve([]);
        }
    })

};

async function searchShop({ shop, page, dataShop, getState }) {
    return new Promise(async (resolve, reject) => {
        await axios
            .post("/api/spytool/search-shop", { shop, page }, tokenConfig(getState))
            .then(async (res) => {
                if (res.data.status === 200) {
                    if (res.data.data.data.length > 0) {
                        dataShop = dataShop.concat(res.data.data.data);
                        page++;
                        await searchShop({ shop, page, dataShop, getState }).then(async (a) => resolve(a))
                    } else {
                        resolve(dataShop);
                    }
                } else {
                    resolve(dataShop);
                }
            })
    })


};

async function searchProduct({ product, page, dataProduct, getState }) {
    return new Promise(async (resolve, reject) => {
        await axios
            .post("/api/spytool/search-product", { product, page }, tokenConfig(getState))
            .then(async (res) => {
                if (res.data.status === 200) {
                    if (res.data.data.data.length > 0) {
                        dataProduct = dataProduct.concat(res.data.data.data);
                        page++;
                        await sleep(1000)
                        await searchProduct({ product, page, dataProduct, getState }).then(async (a) => resolve(a))
                    } else {
                        resolve(dataProduct);
                    }
                } else {
                    resolve(dataProduct);
                }
            })
    })


};

async function searchShopProduct({ id, page, dataProduct, getState }) {
    return new Promise(async (resolve, reject) => {
        await axios
            .post("/api/spytool/search-shop-product", { id, page }, tokenConfig(getState))
            .then(async (res) => {
                if (res.data.status === 200) {
                    if (res.data.data.data.length > 0) {
                        dataProduct = dataProduct.concat(res.data.data.data);
                        page++;
                        await searchShopProduct({ id, page, dataProduct, getState }).then(async (a) => resolve(a))
                    } else {
                        resolve(dataProduct);
                    }
                } else {
                    resolve(dataProduct);
                }
            })
    })


};

async function searchVideo({ keyword, page, dataProduct, getState }) {
    return new Promise(async (resolve, reject) => {
        await axios
            .post("/api/spytool/search-video", { keyword, page }, tokenConfig(getState))
            .then(async (res) => {
                if (res.data.status === 200) {
                    if (res.data.data.data.length > 0) {
                        dataProduct = dataProduct.concat(res.data.data.data);
                        page++;
                        await searchVideo({ keyword, page, dataProduct, getState }).then(async (a) => resolve(a))
                    } else {
                        resolve(dataProduct);
                    }
                } else {
                    resolve(dataProduct);
                }
            })
    })


};

const searchAmazon = (data) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        await axios
            .post("/api/spytool/search-amazon", data, tokenConfig(getState))
            .then(async (res) => {
                if (res.data.status === 200) {
                    console.log(res.data)
                    if (res.data.data.data.length > 0) {
                        resolve(res.data.data.data)
                    } else {
                        resolve([]);
                    }
                } else {
                    resolve([]);
                }
            })
    })

};

export {
    getProductEsty,
    getProductShopEsty,
    uploadTemplateByLink,
    getProductTikTokv1,
    getSuggests,
    getAmazonProducts,
    getTikTokTopSales,
    getTikTokBreakoutProduct,
    getTikTokNewProduct,
    getTikTokHotProduct,
    getTikTokFirstSale,
    searchTikTok,
    searchAmazon
};
