import { Fragment, useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { TreeSelect } from "antd";
import { multiSelectFilter, Comparator } from "react-bootstrap-table2-filter";
import JoditEditor from "jodit-react";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { addProduct } from "actions/productActions";
import { addProductTemplate, getProductTemplate } from "actions/productTemplateAction";
import { uploadImage } from "actions/websiteActions";
import { getCategories, getAttributes } from "actions/categoryAction";
import { getCategoriesProduct } from "actions/categoryProductAction";
import Loading from "components/Loading";
import ModalLinkImages from "./modalLinkImages";
import InputFile from "components/elements/inputFile";
import ImageBox from "components/elements/imageBox";
import { splitVariants, convertKeyValuePair, isNumeric } from "components/Function";
import { ReactTable } from "components/Table";
import ModalUpdateVariant from "./modalUpdateVariant";
import ModalImageSelect from "components/elements/modalImageSelect";
import SelectBox from "components/elements/selectBox";

function Main({
    addProduct,
    addProductTemplate,
    getProductTemplate,
    productTemplate,
    getCategories,
    getAttributes,
    uploadImage,
    splitVariants,
    convertKeyValuePair,
    getCategoriesProduct,
}) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        isCod: "no_cod",
        delivery_option: "default",
        description: `<p data-pm-slice="0 0 []"><strong><em>WE PROVIDE FREE EDITING AND CUSTOMIZATION OF TEXT ON DESIGNS ACCORDING TO YOUR REQUIREMENTS. PLEASE MESSAGE US WITH YOUR REQUESTS.</em></strong></p>
<p><strong><em>HIGH TECH PRINTING</em></strong><em>:</em> Using of high-quality technology to ensure that the quality of the shirts is not tampered with in the process. With this technology, all colors, print images, and text will correctly be impressed on the shirt without rub off, fade, or peel. Making the printing last longer because of the heat transfer process that is superior to the vinyl printing process.</p>
<p><strong>CUSTOM SHIRTS:</strong> Customize in any way, please message us to custom your own design and different color shirts just for you!</p>
<p><strong>BEST CUSTOMER CARE SERVICE:</strong> If you are not happy with our product, please feel free to contact us, we guarantee to give the best solution to you within 24 hours. We will never let you down.</p>
<p><strong>About Us !</strong></p>
<p><strong>OUR CUSTOM SHIRTS FOR WOMEN, MEN</strong></p>
<p>Shirts is the best super soft and high-quality printed shirts. The classic light-weight fitted shirts is made of cotton fabric soft and comfortable to touch, micro-elastic, comfortable and stylish, simple and practical, not easy to shrink, durable.this is a round neck shirts with a simple structure. It has the advantages of breathability, softness, comfort, coolness, sweat absorption and heat dissipation. It can be freely matched from home clothes to popular clothes, suitable for various occasions, as long as you choose the same style of bottoms, you can wear popular styles and different moods.</p>
<p><strong>Various occasions:</strong></p>
<p>Daily casual wear novelty shirts, suitable for many occasions, such as school, work, party, beach, travel, shopping, etc. This printed shirts is suitable for sports, running, jogging, walking, cycling, hiking, gym training or yoga exercises.</p>
<p><strong>Cleaning method:</strong></p>
<p>Cold machine wash in the same color, low temperature drying, high-quality material, not easy to deform after repeated cleaning. Hand washable and machine washable</p>
<p><strong>Tips:</strong></p>
<p><em>Due to the difference in monitor display, the actual color may be slightly different from the image.</em></p>
<p><em>Due to different measurement methods, the measurement difference is 1-3cm.</em></p>
<p><em>Do not bleach. Please reference the size chart of the picture carefully to choose your size.</em></p>
<p><strong>Thanks for visiting our lovely store!</strong></p>
`,
        brand: "No brand",
    });
    const [isEditVariant, setIsEditVariant] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openProductAttrs, setOpenProductAttr] = useState(true);
    const [openProductCom, setOpenProductCom] = useState(true);
    const [templateSelect, setTemplateSelect] = useState("");
    const [categories, setCategories] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [optionsVariants, setOptionsVariants] = useState([]);
    const [variantSelects, setVariantSelects] = useState([]);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(999);
    const [imageSku, setImageSku] = useState(null);
    const [totalSize] = useState(999);
    const [columns, setColumns] = useState([
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
    ]);
    const [categoriesProduct, setCategoriesProduct] = useState("");
    const [listCategoriesProduct, setListCategoriesProduct] = useState([]);

    const { list: templates } = productTemplate;
    const editor = useRef(null);
    const config = useMemo(() => {
        return {
            placeholder: "",
            height: "500px",
        };
    }, []);

    useEffect(() => {
        getCategoriesProduct({ sizeperpage: 100 }).then((data) => {
            setListCategoriesProduct(
                data?.categoryProducts?.map((cate) => ({ value: cate?._id, label: cate.title })) || []
            );
        });
    }, [getCategoriesProduct]);

    useEffect(() => {
        getProductTemplate();
    }, [getProductTemplate]);

    useEffect(() => {
        getCategories().then((data) =>
            setCategories((data || []).map((c) => ({ value: c.id, label: c.local_name, children: c.children })))
        );
    }, [getCategories]);

    useEffect(() => {
        if (formData.category) {
            getAttributes(formData.category).then((d) => setAttributes(d || []));
        }
    }, [getAttributes, formData.category]);

    useEffect(() => {
        let columns_tmp = [];
        for (const [key] of Object.entries(formData?.skus?.[0] || {})) {
            let obj = {};
            if (key === "_id") {
                obj = {
                    dataField: key,
                    text: "",
                    sort: true,
                    editable: false,
                    formatter: (cell, row, rowIndex) => rowIndex + 1,
                };
            } else if (key === "thumbnail") {
                obj = {
                    dataField: key,
                    text: key,
                    sort: true,
                    editable: false,
                    formatter: (cell, row, rowIndex) => (
                        <Fragment>
                            <img
                                src={cell}
                                alt=""
                                className="al-img-table al-cursor-pointer"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = require("components/assets/vendors/images/no-image.png");
                                }}
                            />
                        </Fragment>
                    ),
                };
            } else if (key === "price") {
                obj = {
                    dataField: key,
                    text: key,
                    sort: true,
                    classes: "al-cursor-pointer",
                    formatter: (cell, row, rowIndex) => (
                        <Fragment>
                            {cell} <i className="fa fa-pencil"></i>
                        </Fragment>
                    ),
                    validator: (newValue, row, column) => {
                        if (isNumeric(newValue)) {
                            return true;
                        } else {
                            return {
                                valid: false,
                                message: "INVALID TYPE! TRY AGAIN!",
                            };
                        }
                    },
                };
            } else if (key === "quantity") {
                obj = {
                    dataField: key,
                    text: key,
                    sort: true,
                    classes: "al-cursor-pointer",
                    formatter: (cell, row, rowIndex) => (
                        <Fragment>
                            {cell} <i className="fa fa-pencil"></i>
                        </Fragment>
                    ),
                    validator: (newValue, row, column) => {
                        if (isNumeric(newValue)) {
                            return true;
                        } else {
                            return {
                                valid: false,
                                message: "INVALID TYPE! TRY AGAIN!",
                            };
                        }
                    },
                };
            } else {
                let selectOptions_find = optionsVariants.find((v, i) => {
                    return v["key"] === key;
                });

                if (selectOptions_find) {
                    let selectOptions = selectOptions_find["value"].reduce(
                        (obj, item) => Object.assign(obj, { [item]: item }),
                        {}
                    );
                    obj = {
                        dataField: key,
                        text: key,
                        sort: true,
                        editable: false,
                        classes: "al-custom-options-variants",
                        attrs: {
                            "data-type": key,
                        },
                        formatter: (cell, row, rowIndex) => <Fragment>{selectOptions[cell]}</Fragment>,
                        filter: multiSelectFilter({
                            options: selectOptions,
                            comparator: Comparator.EQ,
                        }),
                    };
                }
            }

            if (obj && Object.keys(obj).length) columns_tmp.push(obj);
        }

        if (columns_tmp.length)
            setColumns([
                ...columns_tmp,
                {
                    dataField: "-",
                    text: t("Actions"),
                    editable: false,
                    formatter: (cell, row, rowIndex, formatExtraData) => (
                        <>
                            <button
                                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={t("Delete")}
                                onClick={() =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        skus: prev?.skus?.filter((sku) => sku._id !== row._id),
                                    }))
                                }
                            >
                                <i className="ki-outline ki-trash fs-2"></i>
                            </button>
                        </>
                    ),
                },
            ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.skus, optionsVariants]);

    const handleChangeForm = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        await addProduct({ ...formData, optionsVariants, categories: categoriesProduct });
        setLoading(false);
    };

    const handleLoadTemplate = () => {
        if (!templateSelect) Swal.fire(t("Error"), t("Please choose template!"), "error");
        const template = templates[templateSelect];
        if (template) {
            setFormData({
                ...template,
                product_name: template.title,
                weight: template.weight?.value,
                weight_type: template.weight?.type,
                isCod: template.isCod ? "on_cod" : "no_cod",
                skus: template.skus,
                d_length: template?.package_dimensions?.length,
                d_width: template?.package_dimensions?.width,
                d_height: template?.package_dimensions?.height,
                d_unit: template?.package_dimensions?.unit,
            });

            setOptionsVariants(template.optionsVariants);
        }
    };

    const handleChangeLoadData = async (e) => {
        const title = Object.values(e).find((f) => f?.name?.toLowerCase() === "title.txt");

        if (title) {
            const reader = new FileReader();
            reader.onload = function () {
                const text = reader.result;
                setFormData((prev) => ({ ...prev, product_name: text }));
            };
            reader.readAsText(title);
        }
        const images = Object.values(e).filter((f) => f.type.includes("image"));
        if (images.length) {
            setLoading(true);
            const postData = await uploadImage(images);
            const links = postData?.arr_images || [];
            setFormData((prev) => ({ ...prev, main_images: links }));
            setLoading(false);
        }
    };

    const onUpdateOptions = (type = "") => {
        if (optionsVariants.length >= 3) {
            return false;
        }
        setOptionsVariants((prev) => [
            ...prev,
            {
                key: "",
                value: [],
                type,
            },
        ]);
    };

    const handleDeleteOption = (key) => {
        if (!isEditVariant) return;
        setOptionsVariants((prev) => prev.filter((_v, k) => k !== key));
    };

    const handleChangeOption = ({ key, values = null, title = null, target }) => {
        setOptionsVariants((prev) => {
            return prev.map((v, k) => {
                if (k === key && values) return { ...v, value: values.map((d) => d.value) };
                if (k === key && title && target)
                    return { ...v, key: target.selectedOptions[0].text, attributeId: title };
                if (k === key && typeof title === "string") return { ...v, key: title };

                return v;
            });
        });
    };

    const handleDoneVariants = () => {
        if (!optionsVariants?.length)
            return setFormData((prev) => ({
                ...prev,
                skus: [],
            }));

        setFormData((prev) => {
            const dataNew = convertKeyValuePair(splitVariants(optionsVariants), formData?.main_images || [], "USD");

            const skusResult = dataNew.map((sku) => {
                const arrCheck = Object.keys(sku).reduce((r, key) => {
                    if (!["_id", "thumbnail", "price", "quantity"].includes(key)) {
                        r.push(key);
                    }
                    return r;
                }, []);
                const dataOld = (prev?.skus || []).find((i) => {
                    return arrCheck.every((kCheck) => i[kCheck] === sku[kCheck]);
                });

                return dataOld ? { ...dataOld, _id: sku._id } : sku;
            });

            return {
                ...prev,
                skus: skusResult,
            };
        });
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        setPage(page);
        setSizePerPage(sizePerPage);
    };

    const handleUploadImage = async (e, key = "main_images") => {
        const images = Object.values(e).filter((f) => f.type.includes("image"));
        if (images.length) {
            setLoading(true);
            const postData = await uploadImage(images);
            const links = postData?.arr_images || [];
            setFormData((prev) => ({ ...prev, [key]: [...(prev?.[key] || []), ...links] }));
            setLoading(false);
        }
    };

    const onSortEnd = (oldIndex, newIndex) => {
        setFormData((prev) => ({
            ...prev,
            main_images: arrayMove(prev?.main_images || [], oldIndex, newIndex),
        }));
    };

    const handleChangeImageSku = (image) => {
        setOptionsVariants((prev) =>
            prev.map((opt) => {
                if (imageSku.key === opt.key) {
                    return { ...opt, image: { ...(opt?.image || {}), [imageSku.item]: image } };
                }

                if (imageSku.key !== opt.key && Object.keys(opt?.image || {})) {
                    return { ...opt, image: {} };
                }

                return opt;
            })
        );
    };

    const handleClearImageVariant = () => {
        setOptionsVariants((prev) =>
            prev.map((opt) => {
                if (imageSku.key === opt.key) {
                    return { ...opt, image: { ...(opt?.image || {}), [imageSku.item]: null } };
                }

                return opt;
            })
        );
    };

    const handleChangeProductAttrs = (data, attrId) => {
        if (!Array.isArray(data)) data = [data];
        const values = data.reduce((result, item) => {
            if (item) {
                let val = { id: item?.value, name: item?.label };
                if (item?.["__isNew__"] || !Number.isInteger(+item?.value)) {
                    val = { name: item.label };
                }
                result.push(val);
            }

            return result;
        }, []);

        setFormData((prev) => {
            let result = [...(prev?.product_attributes || []), { id: attrId, values }];
            const exist = prev?.product_attributes?.find((a) => a.id === attrId);
            if (exist) {
                result = prev?.product_attributes.map((a) => {
                    if (a.id === attrId) return { id: attrId, values };
                    return a;
                });
            }
            return { ...prev, product_attributes: result };
        });
    };

    const handleChangeCertifications = (id, value, key) => {
        setFormData((prev) => {
            let data = prev?.certifications || [];
            data[key] = { id, value };
            return { ...prev, certifications: data };
        });
    };

    return (
        <Fragment>
            <Loading isLoading={loading} />
            <ModalImageSelect
                images={formData?.image_variant || []}
                isOpen={!!imageSku?.toString()}
                onClose={() => setImageSku(null)}
                onSubmit={handleChangeImageSku}
                onClearImage={handleClearImageVariant}
            />
            <div className="card min-w-full">
                <div className="card-body">
                    <div className="d-flex align-items-center flex-wrap gap-5 mb-4">
                        <select
                            className="btn btn-sm border border-danger"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Tempalte"
                            name="template"
                            onChange={(e) => setTemplateSelect(e.target.value)}
                        >
                            <option value="">{t("--Select Template Product--")}</option>
                            {templates.map((v, k) => (
                                <option key={k} value={k}>
                                    {v.title}
                                </option>
                            ))}
                        </select>
                        <button
                            className="btn btn-sm btn-secondary border border-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_app"
                            id="kt_toolbar_primary_button"
                            onClick={handleLoadTemplate}
                        >
                            <i className="ki-outline ki-loading"></i>
                            {t("Load Template")}
                        </button>
                        <button
                            className="btn btn-sm btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_app"
                            id="kt_toolbar_primary_button"
                            onClick={async () => {
                                setLoading(true);
                                await addProductTemplate({
                                    ...formData,
                                    optionsVariants,
                                    categories: categoriesProduct,
                                });
                                setLoading(false);
                            }}
                        >
                            <i className="ki-outline ki-save-2"></i>
                            {t("Save Template")}
                        </button>
                        <button
                            className="btn btn-sm btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_app"
                            id="kt_toolbar_primary_button"
                            onClick={handleSubmit}
                        >
                            <i className="ki-outline ki-cloud-download"></i>
                            {t("Save Product")}
                        </button>
                        <SelectBox
                            options={listCategoriesProduct}
                            name="filter_categories"
                            value={categoriesProduct || ""}
                            onChange={(data) => {
                                setCategoriesProduct(data.value || "");
                                setPage(1);
                            }}
                        />
                        <InputFile onChange={handleChangeLoadData}>
                            <button
                                className="btn btn-sm btn-danger"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_create_app"
                                id="kt_toolbar_primary_button"
                            >
                                <i className="ki-outline ki-cloud-download"></i>
                                {t("Load Data")}
                            </button>
                        </InputFile>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <div>
                            <h3 className="required">{t("Product Image")}</h3>
                            <span className="text-gray-600 text-2sm font-medium">
                                {t(
                                    "It's recommended to include at least 5 images to adequately represent your product."
                                )}
                            </span>
                        </div>
                        <div className="flex items-center mt-5">
                            <ModalLinkImages
                                onSubmit={(data) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        main_images: [...(prev?.main_images || []), ...data],
                                    }));
                                }}
                            />
                            <InputFile onChange={(e) => handleUploadImage(e, "main_images")}>
                                <button
                                    className="btn btn-danger ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_create_app"
                                    id="kt_toolbar_primary_button"
                                >
                                    <i className="ki-outline ki-cloud-download"></i>
                                    {t("Upload Image")}
                                </button>
                            </InputFile>
                        </div>
                        <div className="mt-4">
                            {!!formData?.main_images?.length && (
                                <SortableList
                                    onSortEnd={onSortEnd}
                                    className="al-thumbsContainer"
                                    draggedItemClassName="dragged"
                                >
                                    {formData?.main_images.map((link, key) => (
                                        <SortableItem key={key}>
                                            <div className="al-thumb">
                                                <div className="al-thumbInner position-relative">
                                                    <button
                                                        onClick={() =>
                                                            setFormData((prev) => ({
                                                                ...prev,
                                                                main_images: (prev?.main_images || []).filter(
                                                                    (_s, k) => k !== key
                                                                ),
                                                            }))
                                                        }
                                                        type="button"
                                                        className="btn-delete-image"
                                                    >
                                                        <i className="fa fa-trash" />
                                                    </button>
                                                    <img
                                                        src={link}
                                                        alt=""
                                                        className="al-product-img al-cursor-move w-100 h-100"
                                                        draggable="false"
                                                        style={{ objectFit: "cover" }}
                                                    />
                                                </div>
                                            </div>
                                        </SortableItem>
                                    ))}
                                </SortableList>
                            )}
                        </div>
                        <div className="d-flex mb-4 fv-row fv-plugins-icon-container gap-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold">
                                <span>{t("OptimizedImage Main Image")}:</span>
                            </label>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                    onChange={(e) =>
                                        setFormData((prev) => ({ ...prev, optimizedImage: e.target.checked }))
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <div>
                            <h3 className="">{t("Image Other")}</h3>
                        </div>
                        <div className="flex items-center mt-5 gap-2">
                            <ModalLinkImages
                                onSubmit={(data) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        image_other: [...(prev?.image_other || []), ...data],
                                    }));
                                }}
                            />
                            <InputFile onChange={(e) => handleUploadImage(e, "image_other")}>
                                <button
                                    className="btn btn-danger ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_create_app"
                                    id="kt_toolbar_primary_button"
                                >
                                    <i className="ki-outline ki-cloud-download"></i>
                                    {t("Upload Image")}
                                </button>
                            </InputFile>
                        </div>
                        <div className="mt-4">
                            {!!formData?.image_other?.length &&
                                formData?.image_other.map((link, key) => (
                                    <ImageBox
                                        key={key}
                                        src={link}
                                        onRemove={() => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                image_other: (prev?.image_other || []).filter((_s, k) => k !== key),
                                            }));
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <div>
                            <h3 className="required">{t("Size Chart")}</h3>
                        </div>
                        <div className="flex items-center mt-5 gap-2">
                            <ModalLinkImages
                                onSubmit={(data) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        size_chart: [...(prev?.size_chart || []), ...data],
                                    }));
                                }}
                            />
                            <InputFile onChange={(e) => handleUploadImage(e, "size_chart")}>
                                <button
                                    className="btn btn-danger ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_create_app"
                                    id="kt_toolbar_primary_button"
                                >
                                    <i className="ki-outline ki-cloud-download"></i>
                                    {t("Upload Image")}
                                </button>
                            </InputFile>
                        </div>
                        <div className="mt-4">
                            {!!formData?.size_chart?.length &&
                                formData?.size_chart.map((link, key) => (
                                    <ImageBox
                                        key={key}
                                        src={link}
                                        onRemove={() => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                size_chart: (prev?.size_chart || []).filter((_s, k) => k !== key),
                                            }));
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-8 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">
                                    {t("Product name")}{" "}
                                    <span
                                        className={
                                            formData?.product_name?.length < 140 && formData?.product_name?.length > 40
                                                ? "text-success"
                                                : "text-danger"
                                        }
                                    >
                                        Total: {formData?.product_name?.length}
                                    </span>
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                    "[Brand] + [Content] + [Scope of application] + [Product type] + [Main function/Feature]"
                                )}
                                name="product_name"
                                onChange={handleChangeForm}
                                value={formData?.product_name || ""}
                            />
                        </div>
                        <div className="col-4 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Digit Number")}</span>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="0"
                                name="digit_number"
                                onChange={handleChangeForm}
                                value={formData?.digit_number || ""}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Category")}</span>
                            </label>
                            <TreeSelect
                                showSearch
                                style={{ width: "100%" }}
                                treeNodeFilterProp="label"
                                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                className="h-100"
                                placeholder="Please select"
                                onChange={(cate) => {
                                    // setOptionsVariants([]);
                                    setFormData((prev) => ({
                                        ...prev,
                                        // skus: [],
                                        category: cate,
                                        product_attributes: [],
                                    }));
                                }}
                                treeData={categories}
                                value={formData.category}
                            />
                        </div>
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Brand")}</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("Brand")}
                                name="brand"
                                onChange={handleChangeForm}
                                value={formData?.brand || ""}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Product description")}</span>
                        </label>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 form-text">
                                <i>
                                    <b>Prerequisites:</b>
                                    <ul>
                                        <li>Must conform to html syntax</li>
                                        <li>
                                            {`Currently, it only supports html tags <p> <img> <ul> <ol> <li> <br> <strong> <b> <i> <em> <u>, other HTML tags will be filtered out and will not take effect. `}
                                        </li>
                                        <li>Tags can not be nested</li>
                                        <li>This field character limit needs to be within 10000 characters.</li>
                                        <li>
                                            It is recommended to avoid using Chinese because the copy will be displayed
                                            to local users.
                                        </li>
                                        <li>
                                            The img tag needs to include the src, width, and height attributes, and the
                                            image dimensions can not exceed 4000
                                        </li>
                                        <li>{`<strong> <b> <i> <em> <u> <br> should be used within  <p> and <li>`}</li>
                                    </ul>
                                </i>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <b>Description content code:</b>
                                <ul>
                                    <li>{`{main_image} - Insert Main Image at the position you fill in`}</li>
                                    <li>
                                        {`{random_image} - Insert Image Random in Product Image at the position you fill in`}
                                    </li>
                                    <li>{`{title} - Insert Product Name at the position you fill in`}</li>
                                </ul>
                            </div>
                        </div>
                        <JoditEditor
                            ref={editor}
                            value={formData?.description || ""}
                            config={config}
                            tabIndex={1}
                            onBlur={(newDescription) =>
                                setFormData((prev) => ({ ...prev, description: newDescription }))
                            }
                            onChange={(newDescription) => {}}
                            maxHeight={200}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="">{t("Video")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="https://"
                            name="video"
                            onChange={handleChangeForm}
                            value={formData?.video || ""}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Quantity")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder={t("quantity")}
                            name="quantity"
                            onChange={handleChangeForm}
                            value={formData?.quantity || "0"}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Currency")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="currency"
                            name="currency"
                            onChange={handleChangeForm}
                            value={formData?.currency || "USD"}
                        >
                            <option value="USD">USD</option>
                            <option value="VND">VND</option>
                        </select>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Price")}</span>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("Price")}
                                name="price"
                                onChange={handleChangeForm}
                                value={formData?.price || "0"}
                            />
                        </div>
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Weight with Package")}</span>
                            </label>
                            <div className="d-flex flex-row">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("Weight with Package")}
                                    name="weight"
                                    onChange={handleChangeForm}
                                    value={formData?.weight || ""}
                                />
                                <select
                                    className="btn border border-secondary"
                                    data-control="select2"
                                    data-hide-search="true"
                                    data-placeholder="Status"
                                    name="weight_type"
                                    onChange={handleChangeForm}
                                    value={formData?.weight_type}
                                >
                                    <option value="">{t("Please choose option")}</option>
                                    <option value="KILOGRAM">KILOGRAM</option>
                                    <option value="POUND">POUND</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Dimensions")}</span>
                        </label>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("Length")}
                                name="d_length"
                                onChange={handleChangeForm}
                                value={formData?.d_length || ""}
                            />
                        </div>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("width")}
                                name="d_width"
                                onChange={handleChangeForm}
                                value={formData?.d_width || ""}
                            />
                        </div>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("height")}
                                name="d_height"
                                onChange={handleChangeForm}
                                value={formData?.d_height || ""}
                            />
                        </div>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <select
                                className="btn border border-secondary"
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Status"
                                name="d_unit"
                                onChange={handleChangeForm}
                                value={formData?.d_unit}
                            >
                                <option value="">{t("Please choose option")}</option>
                                <option value="CENTIMETER">CENTIMETER</option>
                                <option value="INCH">INCH</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Delivery options")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Status"
                            name="delivery_option"
                            onChange={handleChangeForm}
                        >
                            <option value="default">{t("Default")}</option>
                            <option value="custom">{t("Custom")}</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="">{t("Cash on Delivery")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Status"
                            name="isCod"
                            onChange={handleChangeForm}
                            value={formData?.isCod || ""}
                        >
                            <option value="no_cod">{t("No COD")}</option>
                            <option value="on_cod">{t("On COD")}</option>
                        </select>
                    </div>

                    {categories?.certifications?.length && (
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2 gap-2">
                                <h3 className="mb-0">{t("Certifications")}</h3>
                            </label>
                            <div className="row">
                                {categories?.certifications?.map((c, key) => (
                                    <div key={key} className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="">{c?.name}</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="https://"
                                            onChange={(e) => {
                                                handleChangeCertifications(c.id, e.target.value, key);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {!!attributes?.length && (
                        <>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2 gap-2">
                                    <h3 className="mb-0">{t("Product Attributes")}</h3>
                                    <div onClick={() => setOpenProductAttr(!openProductAttrs)} className={"rotate-180"}>
                                        <i
                                            className={`ki-duotone ki-${
                                                openProductAttrs ? "up" : "down"
                                            } fs-1 cursor-pointer`}
                                        ></i>
                                    </div>
                                </label>
                                {openProductAttrs && (
                                    <div className="row">
                                        {attributes
                                            .filter(
                                                (attr) =>
                                                    attr?.type === "PRODUCT_PROPERTY" &&
                                                    !(
                                                        !attr.is_customizable &&
                                                        !attr.is_multiple_selection &&
                                                        !attr.is_requried
                                                    )
                                            )
                                            .map((attr, key) => (
                                                <div key={key} className="col-3 mb-2">
                                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                        <span className="">{attr.name}</span>
                                                    </label>
                                                    {attr.is_customizable ? (
                                                        <CreatableSelect
                                                            isClearable
                                                            isMulti={attr.is_multiple_selection}
                                                            onChange={(data) => handleChangeProductAttrs(data, attr.id)}
                                                            value={
                                                                formData?.product_attributes
                                                                    ?.find((a) => a.id === attr.id)
                                                                    ?.values.map((v, i) => {
                                                                        return { label: v.name, value: v.id || v.name };
                                                                    }) || []
                                                            }
                                                            className="w-100"
                                                            options={attr?.values.map((value) => ({
                                                                label: `${value?.name}`,
                                                                value: value?.id,
                                                            }))}
                                                            name="options-variants-value"
                                                            styles={{ height: "100%" }}
                                                            placeholder={t(`Select or Create a ${attr.name} ... `)}
                                                        />
                                                    ) : (
                                                        <Select
                                                            isClearable
                                                            isMulti={attr.is_multiple_selection}
                                                            name="shop"
                                                            options={attr?.values.map((value) => ({
                                                                label: `${value?.name}`,
                                                                value: value?.id,
                                                            }))}
                                                            value={
                                                                formData?.product_attributes
                                                                    ?.find((a) => a.id === attr.id)
                                                                    ?.values.map((v, i) => {
                                                                        return { label: v.name, value: v.id || v.name };
                                                                    }) || []
                                                            }
                                                            className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table w-100"
                                                            classNamePrefix="select"
                                                            onChange={(data) => handleChangeProductAttrs(data, attr.id)}
                                                            placeholder={t(`Select a ${attr.name} ... `)}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2 gap-2">
                                    <h3 className="mb-0">{t("Product Compliance")}</h3>
                                    <div onClick={() => setOpenProductCom(!openProductCom)} className={"rotate-180"}>
                                        <i
                                            className={`ki-duotone ki-${
                                                openProductCom ? "up" : "down"
                                            } fs-1 cursor-pointer`}
                                        ></i>
                                    </div>
                                </label>
                                {openProductCom && (
                                    <div className="row">
                                        {attributes
                                            .filter(
                                                (attr) =>
                                                    attr?.type === "PRODUCT_PROPERTY" &&
                                                    !attr.is_customizable &&
                                                    !attr.is_multiple_selection &&
                                                    !attr.is_requried
                                            )
                                            .map((attr, key) => (
                                                <div key={key} className="col-12 mb-2">
                                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                        <span className="">{attr.name}</span>
                                                    </label>
                                                    {attr.is_customizable ? (
                                                        <CreatableSelect
                                                            isClearable
                                                            isMulti={attr.is_multiple_selection}
                                                            onChange={(data) => handleChangeProductAttrs(data, attr.id)}
                                                            value={
                                                                formData?.product_attributes
                                                                    ?.find((a) => a.id === attr.id)
                                                                    ?.values.map((v, i) => {
                                                                        return { label: v.name, value: v.id || v.name };
                                                                    }) || []
                                                            }
                                                            className="w-100"
                                                            options={attr?.values.map((value) => ({
                                                                label: `${value?.name}`,
                                                                value: value?.id,
                                                            }))}
                                                            name="options-variants-value"
                                                            styles={{ height: "100%" }}
                                                            placeholder={t(`Select or Create a ${attr.name} ... `)}
                                                        />
                                                    ) : (
                                                        <Select
                                                            isClearable
                                                            isMulti={attr.is_multiple_selection}
                                                            name="shop"
                                                            options={attr?.values.map((value) => ({
                                                                label: `${value?.name}`,
                                                                value: value?.id,
                                                            }))}
                                                            value={
                                                                formData?.product_attributes
                                                                    ?.find((a) => a.id === attr.id)
                                                                    ?.values.map((v, i) => {
                                                                        return { label: v.name, value: v.id || v.name };
                                                                    }) || []
                                                            }
                                                            className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table w-100"
                                                            classNamePrefix="select"
                                                            onChange={(data) => handleChangeProductAttrs(data, attr.id)}
                                                            placeholder={t(`Select a ${attr.name} ... `)}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <h3 className="required">{t("Variations")}</h3>
                                </label>
                                <div className="d-flex gap-4 mb-4">
                                    {isEditVariant ? (
                                        <>
                                            {/* <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    onUpdateOptions();
                                                }}
                                            >
                                                Add Option
                                            </button> */}
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    onUpdateOptions("custom");
                                                }}
                                            >
                                                Add Option Custom
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={() => {
                                                    handleDoneVariants();
                                                    setIsEditVariant(false);
                                                }}
                                            >
                                                Done
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => {
                                                setIsEditVariant(true);
                                            }}
                                        >
                                            Edit Option
                                        </button>
                                    )}
                                </div>
                                {optionsVariants.map((opt, key) => (
                                    <div key={key} className="form-group row mb-4">
                                        <div className="col-md-3">
                                            {opt.type === "custom" ? (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("Name")}
                                                    onChange={(e) =>
                                                        handleChangeOption({ key, title: e.target.value, target: null })
                                                    }
                                                    value={opt.key}
                                                    disabled={!isEditVariant}
                                                />
                                            ) : (
                                                <select
                                                    className="form-select"
                                                    data-control="select2"
                                                    data-hide-search="true"
                                                    data-placeholder="Status"
                                                    name="options-variants-key"
                                                    disabled={!isEditVariant}
                                                    onChange={(e) =>
                                                        handleChangeOption({
                                                            key,
                                                            title: e.target.value,
                                                            target: e.target,
                                                        })
                                                    }
                                                    value={opt.attributeId}
                                                >
                                                    <option value="">{t("Please choose attribute")}</option>
                                                    {attributes
                                                        .filter(
                                                            (attr) =>
                                                                !(
                                                                    opt.attributeId !== attr.id &&
                                                                    optionsVariants.find(
                                                                        (variant) => variant.attributeId === attr.id
                                                                    )
                                                                )
                                                        )
                                                        .map((a, ak) => (
                                                            <option key={ak} value={a.id}>
                                                                {a.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            )}
                                        </div>
                                        <div className="col-md-7 d-flex align-items-center">
                                            <CreatableSelect
                                                isMulti
                                                onChange={(e) => handleChangeOption({ key, values: e })}
                                                value={opt.value.map((v, i) => {
                                                    return { label: v, value: v, image: opt?.image?.[v] };
                                                })}
                                                formatOptionLabel={(v) => (
                                                    <div
                                                        onClick={() => setImageSku({ key: opt.key, item: v.value })}
                                                        className="option cursor-pointer"
                                                    >
                                                        {v?.image && (
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    objectFit: "contain",
                                                                }}
                                                                className="mx-2"
                                                                src={v.image}
                                                                alt="country-image"
                                                            />
                                                        )}
                                                        <span>{v.label}</span>
                                                    </div>
                                                )}
                                                isDisabled={!isEditVariant}
                                                className="w-100"
                                                options={[]}
                                                name="options-variants-value"
                                                styles={{ height: "100%" }}
                                            />
                                        </div>
                                        <div className="col-md-2 d-flex align-items-center">
                                            <span type="button" onClick={() => handleDeleteOption(key)}>
                                                <i className="ki-outline ki-trash fs-2 text-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                                {!!optionsVariants?.length && (
                                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <div>
                                            <p className="mb-0">{t("Image Variants")}</p>
                                        </div>
                                        <div className="flex items-center mt-5 gap-2">
                                            <ModalLinkImages
                                                onSubmit={(data) => {
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        image_variant: [...(prev?.image_variant || []), ...data],
                                                    }));
                                                }}
                                            />
                                            <InputFile onChange={(e) => handleUploadImage(e, "image_variant")}>
                                                <button
                                                    className="btn btn-danger ms-2"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_create_app"
                                                    id="kt_toolbar_primary_button"
                                                >
                                                    <i className="ki-outline ki-cloud-download"></i>
                                                    {t("Upload Image")}
                                                </button>
                                            </InputFile>
                                        </div>
                                        <div className="mt-4">
                                            {!!formData?.image_variant?.length &&
                                                formData?.image_variant.map((link, key) => (
                                                    <ImageBox
                                                        key={key}
                                                        src={link}
                                                        onRemove={() => {
                                                            setFormData((prev) => ({
                                                                ...prev,
                                                                image_variant: (prev?.image_variant || []).filter(
                                                                    (_s, k) => k !== key
                                                                ),
                                                            }));
                                                        }}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                )}
                                <div className="al-variants-table">
                                    <ReactTable
                                        columns={columns || []}
                                        data={formData?.skus || []}
                                        alClassName="table-layout-inherit zi-cs"
                                        handleTableChange={handleTableChange}
                                        page={page}
                                        sizePerPage={sizePerPage}
                                        totalSize={totalSize}
                                        isSearchBar={false}
                                        isCellEdit={true}
                                        isSelectRow={true}
                                        remote={{ search: true, pagination: false, sort: false }}
                                        selectRow={{
                                            mode: "checkbox",
                                            clickToSelect: false,
                                            selected: variantSelects,
                                            onSelect: (data, checked) => {
                                                if (checked) {
                                                    setVariantSelects((prev) => [...prev, data._id]);
                                                } else {
                                                    setVariantSelects((prev) =>
                                                        [...prev].filter((d) => d !== data._id)
                                                    );
                                                }
                                            },
                                            onSelectAll: (checked, data) => {
                                                let selects = [];
                                                if (checked) selects = (data || []).map((s) => s._id);
                                                setVariantSelects(selects);
                                            },
                                        }}
                                        groupButton={() => (
                                            <ModalUpdateVariant
                                                disabled={!variantSelects?.length}
                                                onSubmit={(data) => {
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        skus: prev?.skus?.map((sku) => {
                                                            if (variantSelects.includes(sku._id)) {
                                                                return {
                                                                    ...sku,
                                                                    price: data.price,
                                                                    quantity: data.quantity,
                                                                };
                                                            }

                                                            return sku;
                                                        }),
                                                    }));
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

Main.propTypes = {};
const mapStateToProps = ({ productTemplate }) => ({ productTemplate });
const mapDispatchToProps = {
    addProduct,
    addProductTemplate,
    getProductTemplate,
    getCategories,
    getAttributes,
    uploadImage,
    splitVariants,
    convertKeyValuePair,
    getCategoriesProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
