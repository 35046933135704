import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTable } from "components/Table";
import { getShops, renewalShop, deleteShop, cancelDeleteShop } from "actions/shopMessageActions";
import AddShop from "./addShop";
import EditShop from "./editShop";
import { getStaffs } from "actions/teamActions";
import { permissionComponent, FormatterStatus, formatterCreateAt } from "components/Function";
import Loading from "components/Loading";

const Shop = ({
    getShops,
    shop,
    user,
    deleteShop,
    cancelDeleteShop,
    FormatterStatus,
    formatterCreateAt,
    renewalShop,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [shopSelect, setShopSelect] = useState(null);
    const [shopsSelect, setShopsSelect] = useState([]);
    const [filter, setFilter] = useState({});
    const [sort_by, setSortBy] = useState("");
    const [sort_order, setSortOrder] = useState("");

    const { t } = useTranslation();
    const { list, totalsize, isLoading } = shop;

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
            onSort: (field, order) => {
                setSortBy(field);
                setSortOrder(order);
            },
        },
        {
            dataField: "name",
            text: t("Name"),
            sort: true,
        },
        {
            dataField: "region",
            text: t("Region"),
            sort: true,
        },
        {
            dataField: "status",
            text: t("Status"),
            sort: true,
            formatter: (cell, row) => <FormatterStatus status={cell || "Connected"} />,
        },
        {
            dataField: "code",
            text: t("Code"),
            sort: true,
        },
        {
            dataField: "note",
            text: t("Note"),
            sort: true,
            style: {
                maxWidth: "200px",
                wordBreak: "break-all",
                whiteSpace: "pre-line",
            },
            formatter: (cell, row) => cell,
        },
        {
            dataField: "member_note",
            text: t("Member Note"),
            sort: true,
            style: {
                maxWidth: "200px",
                wordBreak: "break-all",
                whiteSpace: "pre-line",
            },
            formatter: (cell, row) => cell,
        },
        {
            dataField: "expired_at",
            text: t("Expired At"),
            sort: true,
            formatter: (cell, row) => (+cell && +cell > 0 ? formatterCreateAt(+cell) : "Since 1905"),
        },
        {
            dataField: "last_order",
            text: t("Last Order"),
            sort: true,
            formatter: (cell, row) => (cell && cell > 0 ? formatterCreateAt(cell) : "Since 1905"),
        },
        {
            dataField: "memberData",
            text: t("Members"),
            sort: true,
            formatter: (cell, row, rowIndex) =>
                cell?.map((v, i) => (
                    <p>
                        - {v?.name} ({v?.email})
                    </p>
                )),
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatter: (cell, row, rowIndex, formatExtraData) =>
                permissionComponent({
                    user: user.user,
                    permission: "UPDATE_SHOP",
                    component:
                        row.status !== "Deleted" ? (
                            <>
                                <button
                                    onClick={() => {
                                        setShopSelect(row);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Edit Note")}
                                >
                                    <i className="ki-outline ki-notepad-edit fs-2"></i>
                                </button>
                                <button
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Renewal Shop"
                                    onClick={() => handleRenewalShop(row._id)}
                                >
                                    <i className="ki-outline ki-disk fs-2"></i>
                                </button>
                                <button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Delete"
                                    onClick={() => handleDelete(row._id)}
                                >
                                    <i className="ki-outline ki-trash fs-2"></i>
                                </button>
                            </>
                        ) : (
                            <button
                                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Cancel Delete"
                                onClick={() => handleCancelDelete(row._id)}
                            >
                                <i className="ki-outline ki-delete-folder fs-2"></i>
                            </button>
                        ),
                }),
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    
    const handleDelete = (id) => {
        deleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleCancelDelete = (id) => {
        cancelDeleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleRenewalShop = async (shopId) => {
        await renewalShop({ shopId });
        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
    };

    const onSelect = (row, isSelect) => {
        let shopsSelectTmp = shopsSelect;
        if (isSelect) {
            shopsSelectTmp.push(row._id);
        } else {
            shopsSelectTmp = shopsSelectTmp.filter((shopId) => shopId !== row._id);
        }
        const new_arr = JSON.stringify(shopsSelectTmp);
        setShopsSelect(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        let shopsSelectTmp = shopsSelect;
        if (isSelect) {
            shopsSelectTmp = row.map((v, i) => v._id);
        } else {
            shopsSelectTmp = [];
        }
        setShopsSelect(shopsSelectTmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: shopsSelect,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    useEffect(() => {
        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
    }, [getShops, page, sizePerPage, searchText, filter, sort_by, sort_order]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const groupButton = () => {
        return (
            <div className="mb-5 d-flex gap-2">
                <AddShop />
            </div>
        );
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    />
                </div>
            </div>
            <EditShop
                data={shopSelect}
                onClose={() => setShopSelect(null)}
                onSubmit={() => {
                    getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
                }}
            />
        </div>
    );
};

const mapStateToProps = ({ shopMessage, user }) => ({ shop: shopMessage, user });
const mapDispatchToProps = {
    getShops,
    getStaffs,
    FormatterStatus,
    formatterCreateAt,
    renewalShop,
    deleteShop,
    cancelDeleteShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
