import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

function ModalEditInformations({ onSubmit, row, place = "", column = "" }) {
    const [isOpen, setIsOpen] = useState(false);
    const [product_id, setProductId] = useState(place === "groupbutton" ? row : [row?.product_id]);
    const [exchange_type_price, setExchangeTypePrice] = useState("rate");
    const [exchange_rate_price, setExchangeRatePrice] = useState(1);
    const [exchange_type_variants, setExchangeTypeVariants] = useState("rate");
    const [exchange_rate_variants, setExchangeRateVariants] = useState(1);
    const [weight, setWeight] = useState(row?.weight?.value || 0);
    const [weight_type, setWeightType] = useState(row?.weight?.type || "POUND");
    const [length, setLength] = useState(row?.dimensions?.length || 0);
    const [width, setWidth] = useState(row?.dimensions?.width || 0);
    const [height, setHeight] = useState(row?.dimensions?.height || 0);
    const [unit, setUnit] = useState(row?.dimensions?.unit || "INCH");
    const { t } = useTranslation();

    useEffect(() => {
        setProductId(place === "groupbutton" ? row : [row?.product_id]);
        setExchangeTypePrice("rate");
        setExchangeRatePrice(1);
        setExchangeTypeVariants("rate");
        setExchangeRateVariants(1);
        setWeight(row?.weight?.value || 0.5);
        setWeightType(row?.weight?.type || "POUND");
        setLength(row?.dimensions?.length || 3);
        setWidth(row?.dimensions?.width || 3);
        setHeight(row?.dimensions?.height || 3);
        setUnit(row?.dimensions?.unit || "INCH");
    }, [isOpen, row, place]);

    const onChange = (e) => {
        if (e.target.name === "exchange_type_price") {
            setExchangeTypePrice(e.target.value);
        }
        if (e.target.name === "exchange_rate_price") {
            setExchangeRatePrice(e.target.value);
        }
        if (e.target.name === "exchange_type_variants") {
            setExchangeTypeVariants(e.target.value);
        }
        if (e.target.name === "exchange_rate_variants") {
            setExchangeRateVariants(e.target.value);
        }
        if (e.target.name === "weight") {
            setWeight(e.target.value);
        }
        if (e.target.name === "weight_type") {
            setWeightType(e.target.value);
        }
        if (e.target.name === "length") {
            setLength(e.target.value);
        }
        if (e.target.name === "width") {
            setWidth(e.target.value);
        }
        if (e.target.name === "height") {
            setHeight(e.target.value);
        }
        if (e.target.name === "unit") {
            setUnit(e.target.value);
        }
    };

    const handleSubmit = () => {
        onSubmit({ product_id, price: { exchange_type: exchange_type_price, exchange_rate: exchange_rate_price }, weight: { value: weight, type: weight_type}, dimensions: { length, width, height, unit }, variants: { exchange_type: exchange_type_variants, exchange_rate: exchange_rate_variants } });
        setIsOpen(false);
    };

    const onKeyPress = (e) => {
        if ((/^[0-9.]+$/).test(e)) {
            e.preventDefault();
        }
    }

    return (
        <Fragment>
            {
                place === "groupbutton" ?
                    <button onClick={() => { setIsOpen(true) }} type="button" className="btn btn-info btn-sm me-1" disabled={product_id.length <= 0}>
                        {t("Edit Informations")}
                    </button>
                :
                    <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit Informations")}
                        onClick={() => { setIsOpen(true) }}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </button>
            }
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-info">{t("Edit informations")}</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    {
                        column === "variants" || column === "all" ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Exchange Type Variants")}</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="exchange_type_variants"
                                            onChange={(e) => { onChange(e) }}
                                            value={exchange_type_variants}
                                        >
                                            {/* {
                                                place !== "groupbutton" ? */}
                                                    <Fragment>
                                                        <option value="rate">{t("Rate")}</option>
                                                        <option value="percent">{t("Percent")}</option>
                                                        <option value="plus">{t("Plus")}</option>
                                                    </Fragment>
                                                {/* : ""
                                            } */}
                                            <option value="fix">{t("Fix")}</option>
                                        </select>
                                    </div>
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Exchange Rate Variants")}</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Exchange Rate")}
                                            name="exchange_rate_variants"
                                            onChange={(e) => { onChange(e) }}
                                            value={exchange_rate_variants}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        : ""
                    }
                    {
                        column === "price" || column === "all" ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Exchange Type Price")}</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="exchange_type_price"
                                            onChange={(e) => { onChange(e) }}
                                            value={exchange_type_price}
                                        >
                                            {/* {
                                                place !== "groupbutton" ? */}
                                                    <Fragment>
                                                        <option value="rate">{t("Rate")}</option>
                                                        <option value="percent">{t("Percent")}</option>
                                                        <option value="plus">{t("Plus")}</option>
                                                    </Fragment>
                                                {/* : ""
                                            } */}
                                            <option value="fix">{t("Fix")}</option>
                                        </select>
                                    </div>
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Exchange Rate Price")}</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Exchange Rate")}
                                            name="exchange_rate_price"
                                            onChange={(e) => { onChange(e) }}
                                            value={exchange_rate_price}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Weight")}</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Weight")}
                                            name="weight"
                                            onChange={(e) => { onChange(e) }}
                                            value={weight}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Weight Type")}</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="weight_type"
                                            onChange={(e) => { onChange(e) }}
                                            value={weight_type}
                                        >
                                            <option value="KILOGRAM">{t("KILOGRAM")}</option>
                                            <option value="POUND">{t("POUND")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span>{t("Dimensions")}</span>
                                    </label>
                                    <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Length")}
                                            name="length"
                                            onChange={(e) => { onChange(e) }}
                                            value={length}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                    <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Width")}
                                            name="width"
                                            onChange={(e) => { onChange(e) }}
                                            value={width}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                    <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Height")}
                                            name="height"
                                            onChange={(e) => { onChange(e) }}
                                            value={height}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                    <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <select
                                            className="form-select"
                                            name="unit"
                                            onChange={(e) => { onChange(e) }}
                                            value={unit}
                                        >
                                            <option value="CENTIMETER">{t("CENTIMETER")}</option>
                                            <option value="INCH">{t("INCH")}</option>
                                        </select>
                                    </div>
                                </div>
                            </Fragment>
                        : ""
                    }
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

// ModalEditInformations.propTypes = {
// };
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditInformations);
