import { Fragment, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

function ModalEditDescriptions({ onSubmit, product_id, descriptions }) {
    const [isOpen, setIsOpen] = useState(false);
    const [newDescriptions, setNewDescriptions] = useState("");
    const { t } = useTranslation();
    const editor = useRef(null);
    const config = useMemo(() => {
        return {
            placeholder: "",
            height: "500px",
        };
    }, []);

    const handleSubmit = () => {
        var final_descriptions = descriptions;
        if (newDescriptions) {
            final_descriptions = newDescriptions;
        }
        onSubmit(product_id, final_descriptions);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button
                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={t("Edit Descriptions")}
                onClick={() => { setNewDescriptions(""); setIsOpen(true) }}
            >
                <i className="ki-outline ki-notepad-edit fs-2"></i>
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-info">{t("Edit descriptions")}</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex justify-content-between mb-5 w-100 flex-column gap-4">
                        <div className="form-group">
                            <div className="mb-5">
                                <JoditEditor
                                    ref={editor}
                                    value={descriptions}
                                    config={config}
                                    tabIndex={1}
                                    onBlur={setNewDescriptions}
                                    onChange={() => { }}
                                    maxHeight={200}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditDescriptions);
